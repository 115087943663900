












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';

import SelectionFilter from '@/shared/components/filters/SelectionFilter.vue';
import { DateType } from '@/pages/Accounting/models/filters/date-filter.model';
import DIVFilter from '@/shared/components/filters/DIVFilter.vue';
import DateFilter from '@/shared/components/filters/DateFilter.vue';
import YMSTypeFilter from '@/pages/YMS/components/YMSTypeFilter.vue';
import moment from 'moment';
import { BS_DATEPICKER_FORMAT } from '@/utils/date.util';

@Component({
  components: { SelectionFilter, DIVFilter, YMSTypeFilter, DateFilter }
})
export default class NavTracFilters extends Vue {
  @Prop() type: 'inventory' | 'dayInOut';

  @Watch('$route', { immediate: true })
  onRouterChange(val, oldVal) {
    // TODO
    // Init module
  }

  selectionTypes = {
    list: [
      { value: 'containerNumber', text: 'Container Number' },
      { value: 'chassisNumber', text: 'Chassis Number' },
      { value: 'bareChassis', text: 'Bare Chassis' },
      { value: 'truckNumber', text: 'Truck Number' },
      { value: 'plateNumber', text: 'Plate Number' }
    ],
    autocompleteList: []
  };

  typeFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: null,
    field: 'type'
  });

  searchFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: null,
    field: 'containerNumber'
  });

  DIVFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: 'division'
  });

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: DateType.CreatedDate
  });

  excelData: any;

  constructor() {
    super();
  }

  search() {
    // AccountingModule.setFilters(
    //   TransformFiltersToJson([this.toFilter, this.fromFilter])
    // );
    // AccountingModule.search(AccountingTypes[this.$route.params.type]);
  }

  async excel() {
    // AccountingModule.setFilters(
    //   TransformFiltersToJson([this.toFilter, this.fromFilter])
    // );
    // await AccountingModule.excel(AccountingTypes[this.$route.params.type]);
    // if (AccountingModule.excelData) {
    //   window.open(AccountingModule.excelData.url);
    // }
  }

  add() {
    this.$bvModal.show('inventory-action-modal');
  }
}
